import PropTypes from 'prop-types'
import { useState } from 'react'

import { translate } from '@/utils/translations'

import Icon from '../Tooltip/Icon'
import DigitalLicenseDialog from './DigitalLicenseDialog'

const Title = ({
  isPackage,
  title,
  translations: { purchase_rent_or_buy_package }
}) => {

  const [isShowing, setIsShowing] = useState(false)

  return (
    <div className='row'>
      <DigitalLicenseDialog isShowing={isShowing} setIsShowing={setIsShowing} />
      <legend className='title line-height-3'>
        {isPackage ? purchase_rent_or_buy_package : title}
        <button
          className='vst-btn--transparent line-height-3'
          onClick={() => setIsShowing(true)}
        >
          <Icon />
          <span className="sr-only">
            {translate('digital_license.open_digital_license_dialog')}
          </span>
        </button>
      </legend>
    </div>
  )
}

export default Title

Title.propTypes = {
  isPackage: PropTypes.bool.isRequired,
  title: PropTypes.string,
  translations: PropTypes.shape({
    purchase_rent_or_buy_package: PropTypes.string.isRequired,
  })
}
