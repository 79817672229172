import { VisuallyHidden } from '@reach/visually-hidden'
import PropTypes from 'prop-types'

import { translate } from '@/utils/translations'
const CloseButton = ({ onDismiss }) => {

  return (
    <button
      className='close-button'
      onClick={onDismiss}
    >
      <VisuallyHidden>{translate('common.close')}</VisuallyHidden>
      <img alt='' src={require(`/app/assets/images/close-icon-black.svg`)} />
    </button>
  )
}

export default CloseButton

CloseButton.propTypes = {
  onDismiss: PropTypes.func.isRequired
}
