require('./index.scss')

import darkIcon from 'images/external-link-icon-blue.svg'
import darkIconGrey from 'images/external-link-icon-dark-grey.svg'
import lightIcon from 'images/external-link-icon-light-blue.svg'
import whiteIcon from 'images/external-link-icon-white.svg'
import PropTypes from 'prop-types'

import { translate } from '@/utils/translations'

const ExternalLinkIcon = ({ theme }) => {
  const iconColor = () => {
    switch (theme) {
      case 'light': return lightIcon
      case 'white': return whiteIcon
      case 'grey': return darkIconGrey
      default: return darkIcon
    }
  }

  return (
    <img
      alt={translate('common.opens_in_new_window')}
      className='external-icon'
      src={iconColor()}
    />
  )
}

export default ExternalLinkIcon

ExternalLinkIcon.propTypes = {
  theme: PropTypes.string
}
