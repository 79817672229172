import PropTypes from 'prop-types'

import Dialog from "@/components/Dialog"
import settings from "@/utils/settings"
import { translate } from "@/utils/translations"

const DigitalLicenseDialog = ({ isShowing, setIsShowing }) => {

  return (
    <Dialog
      heading={translate("digital_license.modal.modal_header")}
      id="digital-license-modal"
      isShowing={isShowing}
      onChangeShow={setIsShowing}
    >
      <div>
        {translate('digital_license.modal.modal_text_client')}
        <a
          href={settings.vst_support_california_disclosure_url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {` ${translate('common.here')}.`}
        </a>
      </div>
    </Dialog>
  )
}

export default DigitalLicenseDialog

DigitalLicenseDialog.propTypes = {
  isShowing: PropTypes.bool,
  setIsShowing: PropTypes.func,
}