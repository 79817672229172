require('./index.scss')

import { Dialog as ReachUIDialog } from '@reach/dialog'
import PropTypes from 'prop-types'
import { createElement } from 'react'

import { DIALOG_TYPES } from '@/constants/notificationTypes'

import CloseButton from './components/CloseButton'

const Dialog = ({
  children,
  heading,
  id,
  isShowing,
  onChangeShow,
  trigger,
  validationType
}) => {
  const onDismiss = () => onChangeShow(false)
  const onHandleShow = () => onChangeShow(true)

  const headingId = `dialog__${id}-heading`

  let className = ['vst-dialog-component', validationType].join(' ')

  return (
    <>
      <If condition={trigger}>
        {createElement('span', { onClick: onHandleShow }, trigger)}
      </If>
      <ReachUIDialog
        aria-labelledby={headingId}
        className={className}
        data-testid='dialog'
        id={id}
        isOpen={isShowing}
        onDismiss={onDismiss}
      >
        <div className='dialog-header'>
          <h2 id={headingId}>{heading}</h2>
          <CloseButton onDismiss={onDismiss} />
        </div>
        <div className='dialog-body'>
          {children}
        </div>
      </ReachUIDialog>
    </>
  )
}

export default Dialog

Dialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  heading: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isShowing: PropTypes.bool.isRequired,
  onChangeShow: PropTypes.func.isRequired,
  trigger: PropTypes.element,
  validationType: PropTypes.oneOf([DIALOG_TYPES.error, DIALOG_TYPES.success])
}
